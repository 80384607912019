
































































































































































































































































.bgc-large {
  background: rgba(8, 9, 36, 0);
  box-shadow: 0px 0px 88px 7px rgba(16, 108, 222, 0.22),
    0px 0px 11px 3px rgba(0, 114, 255, 0.8);
  border-radius: 10px;
  color: #fff;
  border: none;
}
header {
  height: 9%;
  color: #fff;
}
.header {
  display: flex;
  align-items: center;
  height: 100%;
  .weekBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h2 {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }
}
.sectionBoxs {
  height: 100%;
  background: transparent;
}

.el-form-item {
  width: 30%;
  margin-bottom: 0;
  height: 1.5rem;
}

.pageContol {
  background-image: url("../../assets/BG.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.studentBox {
  display: flex;
  padding: 2rem 0;
}
.el-form-item {
  width: 30%;
  margin-bottom: 0;
  height: 1.5rem;
}
.seamless-warp {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 0 1rem 0;
}
.ulTitle {
  font-size: 1rem;
  // padding: 0 2.35rem;
  li {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #ffffff;
    
    cursor: pointer;
    span {
      text-align: left;
      flex: 1;
      padding: 0.5rem 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 100%;
    }
  }
  .projectNum {
    span {
      color: #52ffff;
    }
  }
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
